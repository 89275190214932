export const options = [
  {
    value: 'https://admin.agelessrx.prod.imaware.dev',
    label: 'Ageless RX',
  },
  {
    value: 'https://admin.benji.prod.imaware.dev',
    label: 'Blueberry',
  },
  {
    value: 'https://admin.elo-health.prod.imaware.dev',
    label: 'Elo Health',
  },
  {
    value: 'https://admin.imaware.prod.imaware.dev',
    label: 'imaware',
  },
  {
    value: 'https://admin.nimbus.prod.imaware.dev',
    label: 'Nimbus',
  },
  {
    value: 'https://admin.parsley-health.prod.imaware.dev',
    label: 'Parsley Health',
  },
];

export const portalMap = {
  ageless: options[0],
  benji: options[1],
  'elo-health': options[2],
  localhost: options[3],
  imaware: options[3],
  'parsley-health': options[4],
};