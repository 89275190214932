import styled from 'styled-components';

import searchIcon from 'assets/images/search-icon.png';
import patientIcon from 'assets/images/patient-icon.png';
import imawareLogo from 'assets/images/imaware-logo.png';

interface ElementProps {
  active?: boolean;
  icon: 'search' | 'create';
}

const iconMap = {
  search: searchIcon,
  create: patientIcon,
};

interface HeaderProps {
  isImawareEmployee: boolean;
}

export default styled.div<HeaderProps>`
  background: url(${imawareLogo}) no-repeat;
  margin-left: 25px;
  margin-top: ${({ isImawareEmployee }) =>
    isImawareEmployee ? '18px' : '25px'};
  background-position-y: ${({ isImawareEmployee }) =>
    isImawareEmployee ? '8px' : ''};
  background-size: 120px 21px;
  background-color: white;
  height: 68px;
  width: 100%;
`;

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const SideNav = styled.div`
  background-color: white;
  min-width: 240px;
  margin-top: 68px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SideNavElement = styled.div<ElementProps>`
  ::before {
    display: ${({ active }) => (active ? 'block' : 'none')};
    content: 'i';
    background-color: #b4e2d7;
    position: relative;
    color: #b4e2d7;
    background-position-x: 20px;
    border-radius: 9px;
    font-size: 1px;
    left: -62px;
    height: 40px;
    width: 6px;
  }
  :hover {
    cursor: pointer;
  }
  background: ${({ icon }) => `url(${iconMap[icon]}) no-repeat`};
  background-position-x: 30px;
  background-position-y: 10px;
  height: 44px;
  display: flex;
  align-items: center;
  padding-left: ${({ active }) => (active ? '65px' : '71px')};
  font-family: Lato;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  background-color: ${({ active }) =>
    active ? 'rgba(180, 226, 215, 0.15)' : 'white'};
`;

export const ContentContainer = styled.div`
  background-color: #e5e5e5;
  width: 100%;
  height: 100%;
`;

export const LogoutButton = styled.button`
  background: white !important;
  color: black !important;
  width: 192px;
  margin-left: 24px;
  border-radius: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #000000;
  font-family: Lato;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 76px;
  :hover {
    cursor: pointer;
  }
`;
