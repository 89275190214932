import React from 'react';
import {
  components,
  IndicatorProps,
  ValueContainerProps,
  OptionProps,
} from 'react-select';

import indicatorIcon from 'assets/images/indicator-icon.png';
import { CSSObject } from 'styled-components';
import { FieldError } from 'react-hook-form';

export const Option = (props: OptionProps<any, any>) => (
  <div>
    <components.Option {...props}>
      <input
        type={
          props.selectProps.placeholder === 'Last Updated'
            ? 'radio'
            : 'checkbox'
        }
        checked={props.isSelected}
        onChange={() => null}
      />{' '}
      <label>{props.label}</label>
    </components.Option>
  </div>
);

export const DropdownIndicator = (props: IndicatorProps<any, any>) => (
  <components.DropdownIndicator {...props}>
    <img src={indicatorIcon} />
  </components.DropdownIndicator>
);

export const MultiValueLabel = () => null;
export const MultiValueRemove = () => null;
export const ClearIndicator = () => null;

export const renderContent = (
  value: { value: string; label: string }[] | null,
  placeholder: React.ReactNode,
  children: React.ReactNode,
) => {
  const childArray = React.Children.toArray(children);
  const lastChild = childArray[childArray.length - 1];
  let selectedType = placeholder === 'Test Type' ? 'Tests' : 'Statuses';
  const checkValue =
    value?.length === 1
      ? value[0].label
      : `${value?.length} ${selectedType} ${'Selected'}`;
  if (value === null || !value.length) {
    return childArray;
  }
  if (value.length >= 0) {
    return (
      <>
        {checkValue}
        {lastChild}
      </>
    );
  }
};

export const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<any, any>) => {
  const { selectProps } = props;
  const { value, placeholder } = selectProps;
  return (
    <components.ValueContainer {...props}>
      {renderContent(value, placeholder, children)}
    </components.ValueContainer>
  );
};

export const selectStyles = {
  control: (provided: CSSObject) => ({
    ...provided,
    border: '2px solid transparent',
    height: '42px',
    borderRadius: '0px 4px 4px 0',
  }),
  valueContainer: (provided: CSSObject) => ({
    ...provided,
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    padding: '2px 15px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    marginRight: '7px',
  }),
  placeholder: () => ({
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.7)',
  }),
  singleValue: () => ({
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.7)',
  }),
  multiValue: (provided: CSSObject) => ({
    ...provided,
    background: 'none',
    marginBottom: '20px',
  }),
  menuList: () => ({
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.7)',
  }),
  option: (provided: CSSObject) => ({
    ...provided,
    backgroundColor: 'white',
    fontFamily: 'Roboto',
    color: 'rgba(0, 0, 0, 0.7)',
    ':active': {
      backgroundColor: 'white',
      background: 'white',
    },
  }),
};

export const getStyles = (error?: FieldError) => ({
  ...selectStyles,
  ...(error && {
    control: (provided: CSSObject) => ({
      ...provided,
      border: '2px solid #D80000',
      height: '42px',
      borderRadius: '0px 4px 4px 0',
    }),
  }),
});
