import { Patient } from 'types';

export const PATIENT_FIELDS = `
    id
    name {
      family
      given
    }
    telecom {
      system
      value
    }
    address {
      city
      state
      postalCode
      country
      line
    }
    gender
    birthDate
    testHistory {
        testType
        kitId
        activationDate
        trackingPatient {
          carrier
          trackingNumber
        }
        trackingLab {
          carrier
          trackingNumber
        }
        kitStatus {
          status
          timestamp
        }
      }
`;

export const getPatientName = (patient: Patient | null) =>
  patient &&
  `${patient?.name?.[0].given.join(' ')} ${patient?.name?.[0].family}`;
