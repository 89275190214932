import { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';

import LoadingErrorWrapper from 'components/LoadingErrorWrapper';
import Navigation from 'components/Navigation';
import SearchBar from './components/SearchBar';

import {
  READ_CACHED_RESULTS,
  SEARCH_PATIENT,
  WRITE_CACHED_RESULTS,
} from './queries';
import { SearchBarContainer, SearchButton, SearchHelperText } from './styles';
import { Patient, SearchPageProps } from 'types';
import SearchResults from './components/SearchResults';
import TestStatusDropdown from './components/TestStatusDropdown';
import { formatSearchParams } from './utils';
import SearchDropdown from './components/SearchDropdown';

const SearchPage = () => {
  const [searchResponse, setSearchResults] = useState<Patient[] | null>(null);
  const [searchResultType, setSearchResultType] = useState('');
  const { enableAdvancedSearch } = useFlags();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SearchPageProps>({
    mode: 'onBlur',
    defaultValues: {
      searchTerm: '',
      testStatus: [],
    },
  });

  const searchTerm = watch('searchTerm');
  const searchType = watch('searchType')?.value;
  const testStatus = watch('testStatus').length;
  const emptySearch = Boolean(!searchType && !testStatus && !searchTerm);

  const [
    searchPatient,
    { error: searchPatientError, loading: searchPatientLoading, client },
  ] = useLazyQuery(SEARCH_PATIENT, {
    fetchPolicy: 'network-only',
    onCompleted: ({ searchPatient }) => {
      setSearchResults(searchPatient);
      client.writeQuery({
        query: WRITE_CACHED_RESULTS,
        data: { searchPatient },
      });
    },
    onError: (err) => console.error(err),
  });

  useEffect(() => {
    const data = client.readQuery({
      query: READ_CACHED_RESULTS,
    });
    if (data?.searchPatient) {
      setSearchResults(data.searchPatient);
    }
  }, [client]);

  const onSubmit: SubmitHandler<SearchPageProps> = (data) => {
    const { searchType } = formatSearchParams(data);
    setSearchResultType(searchType);
    searchPatient({
      variables: {
        searchParams: formatSearchParams(data),
      },
    });
  };

  const onSearch = handleSubmit(onSubmit);
  return (
    <Navigation
      page="search"
      render={() => (
        <>
          <SearchBarContainer>
            <div style={{ display: 'flex', marginBottom: '12px' }}>
              <SearchBar
                control={control}
                onSearch={onSearch}
                emptySearch={emptySearch}
              />
              <SearchDropdown
                control={control}
                emptySearch={emptySearch}
                searchTerm={searchTerm}
              />
              <SearchButton
                onClick={onSearch}
                errors={errors}
                data-testid="submit-button"
              >
                Search
              </SearchButton>
            </div>
            {enableAdvancedSearch ? (
              <div style={{ display: 'flex' }}>
                <TestStatusDropdown
                  control={control}
                  emptySearch={emptySearch}
                />
              </div>
            ) : null}
            <SearchHelperText>
              For best results, please search using as few search criteria as
              possible.
            </SearchHelperText>
          </SearchBarContainer>
          <LoadingErrorWrapper
            loading={searchPatientLoading}
            error={searchPatientError}
            styles={{ width: '100%', height: '100vh' }}
          >
            <SearchResults
              searchResults={searchResponse}
              searchType={searchResultType}
              searchTerm={searchTerm}
            />
          </LoadingErrorWrapper>
        </>
      )}
    />
  );
};

export default SearchPage;
