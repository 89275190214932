import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import HeaderContainer, {
  ContentContainer,
  LogoutButton,
  MainContainer,
  SideNav,
  SideNavElement,
} from './styles';
import CompanyDropdown from './components/CompanyDropdown';

const Navigation = ({
  render,
  page,
}: {
  render: () => React.ReactElement;
  page: string;
}) => {
  const history = useHistory();
  const { logout } = useAuth0();
  const isImawareEmployee =
    localStorage.getItem('isImawareEmployee') === 'true';
  const onSearch = () => history.push('/');
  const onCreatePatient = () => history.push('/create-patient');
  const onLogout = () => logout({ returnTo: window.location.origin });
  const onChange = (event: { value: string; label: string }) => {
    document.location = event.value;
  };

  return (
    <>
      <HeaderContainer isImawareEmployee={isImawareEmployee}>
        {isImawareEmployee ? <CompanyDropdown onChange={onChange} /> : null}
      </HeaderContainer>
      <MainContainer>
        <SideNav>
          <div>
            <SideNavElement
              active={page === 'search'}
              icon="search"
              onClick={onSearch}
            >
              Search
            </SideNavElement>
            <SideNavElement
              active={page === 'create'}
              icon="create"
              onClick={onCreatePatient}
            >
              Create New Patient
            </SideNavElement>
          </div>
          <LogoutButton onClick={onLogout}>Logout</LogoutButton>
        </SideNav>
        <ContentContainer>{render()}</ContentContainer>
      </MainContainer>
    </>
  );
};

export default Navigation;
