import { Patient, SearchPatientRow } from 'types';

export const formatPatient = (row: Patient): SearchPatientRow => {
  const { id, name = [], telecom, testHistory } = row;

  const { family, given } = name[0];
  const email = telecom.find((t) => t.system === 'email')?.value ?? '';

  return {
    id,
    name: `${given[0]} ${family}`,
    email,
    testHistory,
  };
};
