// Adapted from
// https://github.com/boxwise/boxtribute/blob/a9abcea8242066eb591419401e899418465724ed/react/src/ApolloWrapper.tsx

import React, { useState, useEffect } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';

interface Props {
  children: React.ReactNode;
}

const ApolloWrapper = ({ children }: Props) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [auth0Token, setAuth0Token] = useState<String>('');

  const httpLink = createHttpLink({
    uri:
      window?.config?.REACT_APP_GRAPHQL_ENDPOINT ??
      process.env.REACT_APP_GRAPHQL_ENDPOINT,
    fetch: async (input, init) => {
      console.info(JSON.stringify(init));
      const res = await fetch(input, init);
      console.info(JSON.stringify(res));
      return res;
    },
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 1000,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error) => !!error,
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions, locations, path }) => {
        if (extensions?.code === 'UNAUTHENTICATED') {
          return (document.location = '/login');
        }
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          JSON.stringify(message, null, 2),
          JSON.stringify(locations, null, 2),
          JSON.stringify(path, null, 2),
        );
      });
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Patient: {
        fields: {
          id(id: string) {
            return id;
          },
        },
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  });

  useEffect(() => {
    const getAuth0Token = async () => {
      const token = isAuthenticated ? await getAccessTokenSilently() : '';
      setAuth0Token(token);
    };
    getAuth0Token();
  }, [isAuthenticated, getAccessTokenSilently]);

  const auth0Link = setContext((_, { headers, ...rest }) => {
    if (!auth0Token) return { headers, ...rest };

    return {
      ...rest,
      headers: {
        ...headers,
        Authorization: `Bearer ${auth0Token}`,
      },
    };
  });

  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([errorLink, retryLink, auth0Link, httpLink]),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
