import { Control, Controller } from 'react-hook-form';
import Select, { components, IndicatorProps } from 'react-select';

import indicatorIcon from 'assets/images/indicator-icon.png';

import { selectStyles } from './utils';
import DropdownContainer from './styles';
import { PlanDefinition } from 'types';

const DropdownIndicator = (props: IndicatorProps<any, any>) => (
  <components.DropdownIndicator {...props}>
    <img src={indicatorIcon} />
  </components.DropdownIndicator>
);

const TestTypeDropdown = ({
  control, planDefinitions
}: {
  control: Control<{ testType: { value: string; label: string } }>, planDefinitions?: PlanDefinition[];
}) => {
  const options = planDefinitions?.map(pd => {
    return { value: pd.id, label: pd.label }
  });
  return (
    <DropdownContainer>
      <Controller
        control={control}
        name="testType"
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <Select
              options={options}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              menuPlacement="auto"
              menuPosition="fixed"
              placeholder="Select Test..."
              components={{ DropdownIndicator }}
              styles={selectStyles}
            />
          </>
        )}
      />
    </DropdownContainer>
  );
};

export default TestTypeDropdown;
