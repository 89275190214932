import {
  DropdownIndicator,
  getStyles,
} from 'containers/SearchPage/components/SharedDropdownComponents';
import Select from 'react-select';

import DropdownWrapper, { dropdownStyles } from './styles';
import { PortalHostname } from 'types';
import { options, portalMap } from './utils';

// eslint-disable-next-line no-unused-vars
const CompanyDropdown = ({ onChange }: { onChange: (event: any) => void }) => (
  <DropdownWrapper data-testid="company-dropdown">
    <Select
      options={options}
      onChange={onChange}
      value={portalMap[window.location.origin as PortalHostname]}
      components={{ DropdownIndicator }}
      styles={{
        ...getStyles(),
        ...dropdownStyles,
      }}
    />
  </DropdownWrapper>
);

export default CompanyDropdown;
