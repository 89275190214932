import { SearchPageProps } from 'types';

export const formatSearchParams = (data: SearchPageProps) => {
  const {
    testStatus: testStatusRaw,
    searchTerm,
    searchType: searchTypeRaw = { value: '' },
  } = data;
  let testStatus;
  const { value: searchType } = searchTypeRaw;
  if (testStatusRaw) {
    testStatus = testStatusRaw.map((type) => type.value);
  }
  return { testStatus, searchTerm, searchType };
};
