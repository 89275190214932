import styled from 'styled-components';

interface ContainerProps {
  createSuccess: boolean | null;
}

export default styled.div<ContainerProps>`
  margin-left: 24px;
  margin-right: 48px;
  margin-bottom: 80px;
  height: 100vh;
  ${({ createSuccess }) =>
    createSuccess &&
    `
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `};
`;

export const CreatePatientHeader = styled.h1`
  font-family: Lora;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.8px;
  margin: 15px 0;
`;
