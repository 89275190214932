import { ApolloError } from '@apollo/client';

import LoadingErrorWrapper from 'components/LoadingErrorWrapper';

import TestRow from './components/TestRow';
import PatientTestsContainer, {
  HeaderColumn,
  PatientTableHeader,
  ResultsHeader,
  NoTestContainer,
} from './styles';
import './index.css';
import { TestHistory } from 'types';

const PatientTestTable = ({
  testHistory,
  getResultReport,
  loading,
  error,
}: {
  testHistory: TestHistory[]; // eslint-disable-next-line no-unused-vars
  getResultReport: (data: {
    variables: { kitId: string; patientId: string };
  }) => void;
  loading: boolean;
  error?: ApolloError;
}) => {
  const onGetResultReport = (kitId: string, patientId: string) =>
    getResultReport({ variables: { kitId, patientId } });

  const renderTests = () =>
    testHistory.map((test) => (
      <TestRow
        key={test.kitId}
        test={test}
        getResultReport={onGetResultReport}
      />
    ));

  return (
    <div>
      <PatientTableHeader>Patient Tests</PatientTableHeader>
      <PatientTestsContainer>
        <LoadingErrorWrapper loading={loading} error={error}>
          {testHistory && testHistory.length > 0 ? (
            <>
              <ResultsHeader>
                <HeaderColumn>Test Type</HeaderColumn>
                <HeaderColumn>Kit ID</HeaderColumn>
                <HeaderColumn>Activation Date</HeaderColumn>
                <HeaderColumn>Kit Status</HeaderColumn>
                <HeaderColumn>Tracking # To Patient</HeaderColumn>
                <HeaderColumn>Tracking # To Lab</HeaderColumn>
              </ResultsHeader>
              {renderTests()}
            </>
          ) : (
            <NoTestContainer>
              This patient does not currently have any tests
            </NoTestContainer>
          )}
        </LoadingErrorWrapper>
      </PatientTestsContainer>
    </div>
  );
};

export default PatientTestTable;
