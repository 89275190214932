import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';

import {
  Option,
  DropdownIndicator,
  MultiValueLabel,
  MultiValueRemove,
  ClearIndicator,
  ValueContainer,
  getStyles,
} from '../SharedDropdownComponents';

import DropdownContainer from './styles';
import { options } from './utils';
import { SearchPageProps } from 'types';

const TestStatusDropdown = ({
  control,
  emptySearch,
}: {
  control: Control<SearchPageProps>;
  emptySearch: boolean;
}) => (
  <DropdownContainer>
    <Controller
      control={control}
      name="testStatus"
      rules={{ required: emptySearch }}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <Select
          isMulti
          options={options}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          menuPlacement="auto"
          menuPosition="fixed"
          placeholder="Status"
          components={{
            DropdownIndicator,
            MultiValueLabel,
            MultiValueRemove,
            ValueContainer,
            ClearIndicator,
            Option,
          }}
          styles={getStyles(error)}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          allowSelectAll={true}
        />
      )}
    />
  </DropdownContainer>
);

export default TestStatusDropdown;
