import React from 'react';
import ButtonContainer from './styles';

const Button = ({
  children,
  onClick,
  dataTestId,
  secondary,
  styles,
}: {
  children: React.ReactChild;
  onClick: () => void;
  dataTestId?: string;
  secondary?: boolean;
  styles?: {
    padding?: string;
    fontSize?: string;
    lineHeight?: string;
  };
}) => {
  return (
    <ButtonContainer
      onClick={onClick}
      data-testid={dataTestId}
      secondary={secondary}
      styles={styles}
    >
      {children}
    </ButtonContainer>
  );
};

export default Button;
