import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SearchPage from 'containers/SearchPage';
import PatientProfilePage from 'containers/PatientProfilePage';
import CreatePatientPage from 'containers/CreatePatientPage';
import ProtectedRoute from 'components/ProtectedRoute';
import { useAuth0 } from '@auth0/auth0-react';
import SentryDebug from 'containers/SentryDebug';

// yarn sentry-cli releases files 1.0-local-partner-portal upload-sourcemaps ./build --project partner-portal-frontend --org imaware
const sentryRelease =
  window?.config?.REACT_APP_RELEASE_TAG ?? process.env.REACT_APP_RELEASE_TAG;
const sentryEnvironment = 
  window?.config?.REACT_APP_ENVIRONMENT ?? process.env.REACT_APP_ENVIRONMENT;
Sentry.init({
  dsn: 'https://dae1ef736db94dbc857bb99252f6b776@o1212911.ingest.sentry.io/6402980',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: !!sentryRelease, // turn off Sentry for local development by default
  release: sentryRelease || '1.0-local-partner-portal',
  environment: sentryEnvironment || 'local',
});

function App() {
  const { getAccessTokenSilently, user } = useAuth0();
  useEffect(() => {
    const getUserToken = async () => {
      const token = await getAccessTokenSilently({
        audience:
          window?.config?.REACT_APP_AUTH0_AUD ??
          process.env.REACT_APP_AUTH0_AUD,
        scope: 'openid profile email',
      });
      const isImawareEmployee =
        user?.email?.includes('imaware.health').toString() ?? 'false';
      localStorage.setItem('isImawareEmployee', isImawareEmployee);
      localStorage.setItem('accessToken', token);
    };
    getUserToken();
  }, [getAccessTokenSilently, user]);

  return (
    <Router>
      <Switch>
        <Route path="/debug-sentry" component={SentryDebug} />
        <ProtectedRoute path="/create-patient" component={CreatePatientPage} />
        <ProtectedRoute
          path="/profile/:patientId"
          component={PatientProfilePage}
        />
        <ProtectedRoute path="/" component={SearchPage} />
      </Switch>
    </Router>
  );
}

export default App;
