import { gql } from '@apollo/client';

const SEARCH_PATIENT_FIELDS = `
  id
  name {
    family
    given
  }
  telecom {
    system
    value
  }
  address {
    city
    state
    postalCode
    country
    line
  }
  gender
  birthDate
  testHistory {
    testType
    kitId
    activationDate
    trackingPatient {
      carrier
      trackingNumber
    }
    trackingLab {
      carrier
      trackingNumber
    }
    kitStatus {
      status
      timestamp
    }
  }

`;

export const WRITE_CACHED_RESULTS = gql`
  query WriteSearchResults {
    searchPatient {
       ${SEARCH_PATIENT_FIELDS}
    }
  }
`;

export const READ_CACHED_RESULTS = gql`
  query GetSearchResults {
    searchPatient {
      ${SEARCH_PATIENT_FIELDS}
    }
  }
  `;

export const SEARCH_PATIENT = gql`
  query SearchPatient($searchParams: SearchParams!) {
    searchPatient(searchParams: $searchParams) {
       ${SEARCH_PATIENT_FIELDS}
    }
  }
`;

export const SEARCH_RESULTS = gql`
  query SearchResults($searchParams: SearchParams!) {
    searchResults(searchParams: $searchParams) {
      testType
      kitId
      kitStatus
      deliveredToPatientDate
      collectedDate
      recievedAtLabDate
      results {
        basedOn {
          reference
          display
        }
        code {
          coding {
            code
            display
            system
          }
          text
        }
        effectiveDateTime
        focus {
          display
          reference
        }
        id
        identifier {
          type {
            coding {
              display
              userSelected
            }
            text
          }
          use
          value
        }
        referenceRange {
          low {
            unit
            value
            comparator
            system
            code
          }
          high {
            unit
            value
            comparator
            system
            code
          }
        }
        resourceType
        status
        subject {
          display
          reference
        }
        valueQuantity {
          unit
          value
          comparator
          system
          code
        }
        valueCodeableConcept {
          coding {
            display
            userSelected
          }
          text
        }
        valueString
        valueBoolean
        valueInteger
        valueRange {
          low {
            unit
            value
            comparator
            system
            code
          }
          high {
            unit
            value
            comparator
            system
            code
          }
        }
        valueRatio {
          numerator {
            unit
            value
            comparator
            system
            code
          }
          denominator {
            unit
            value
            comparator
            system
            code
          }
        }
        valueSampledData {
          origin {
            unit
            value
            comparator
            system
            code
          }
          period
          factor
          lowerLimit
          upperLimit
          dimensions
          data
        }
        valueTime
        valueDateTime
        valuePeriod {
          start
          end
        }
      }
    }
  }
`;
