import { CSSObject } from 'styled-components';

export const options = [
  {
    value: 'kitId',
    label: 'Kit ID',
  },
  {
    value: 'name',
    label: 'First Name',
  },
  {
    value: 'family',
    label: 'Last Name',
  },
  {
    value: 'telecom',
    label: 'Email',
  },
  {
    value: 'trackingNumber',
    label: 'Tracking Number',
  },
];

export const selectStyles = {
  control: (provided: CSSObject) => ({
    ...provided,
    border: 'none',
    height: '42px',
    borderRadius: '0px 4px 4px 0',
  }),
  valueContainer: (provided: CSSObject) => ({
    ...provided,
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    padding: '2px 15px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    marginRight: '7px',
  }),
  placeholder: () => ({
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.7)',
  }),
  singleValue: () => ({
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.7)',
  }),
  menuList: () => ({
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.7)',
  }),
};
