import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { SearchPageProps } from 'types';
import StyledSearchBar, { ErrorText } from './styles';

const SearchBar = ({
  control,
  onSearch,
  emptySearch,
}: {
  control: Control<SearchPageProps>;
  onSearch: () => Promise<void>;
  emptySearch: boolean;
}) => {
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement> | undefined) => {
    if (e?.key === 'Enter' && !e.shiftKey) {
      onSearch();
    }
  };

  return (
    <Controller
      control={control}
      name="searchTerm"
      rules={{
        required: emptySearch,
      }}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <>
          {error ? (
            <ErrorText>Please search and/or select a category</ErrorText>
          ) : null}
          <StyledSearchBar
            onChange={onChange}
            error={error}
            onBlur={onBlur}
            value={value}
            onKeyPress={onKeyPress}
            data-testid="search-input"
            placeholder="Search by name, email, kit ID, tracking number, etc"
          />
        </>
      )}
    />
  );
};

export default SearchBar;
