import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import Navigation from 'components/Navigation';
import PatientForm from 'components/PatientForm';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper';
import PatientTestTable from 'components/PatientTestTable';

import ActivateKit from './components/ActivateKit';
import OrderTest from './components/OrderTest';

import { Patient } from 'types';

import { getPatientName } from './utils';
import { GET_PATIENT, GET_RESULT_REPORT } from './queries';
import PageContainer, {
  BackButton,
  PatientInformationContainer,
  PatientName,
  SidePanelContainer,
} from './styles';

const PatientProfilePage = () => {
  const history = useHistory();
  const [patient, setPatient] = useState<Patient>({} as Patient);
  const { patientId } = useParams<{ patientId: string }>();

  const { error, loading } = useQuery(GET_PATIENT, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getPatient }) => setPatient(getPatient),
    onError: (err) => console.error(err),
    variables: {
      id: patientId,
    },
  });

  const [
    getResultReport,
    { loading: getReportLoading, error: getReportError },
  ] = useMutation(GET_RESULT_REPORT, {
    onCompleted: ({ getResultReport: reportBuffer }) => {
      const { name } = patient;
      const { given, family } = name[0];
      const currentDate = format(new Date(), 'MMM d, yyyy');
      const blob = new Blob([Buffer.from(reportBuffer, 'base64')], {
        type: 'application/pdf',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      const fileName = `Report - ${given[0]} ${family} - ${currentDate}.pdf`;
      link.download = fileName;
      link.click();
    },
    onError: (err) => console.error(err),
  });

  const onGoBack = () => history.goBack();

  return (
    <div>
      <Navigation
        page="search"
        render={() => (
          <>
            <LoadingErrorWrapper
              loading={loading}
              styles={{ height: '100vh' }}
              error={error}
            >
              <PageContainer>
                <div className="flex">
                  <PatientInformationContainer>
                    <BackButton onClick={onGoBack}>
                      Back to search results
                    </BackButton>
                    <PatientName>{getPatientName(patient)}</PatientName>
                    <PatientForm patient={patient} />
                  </PatientInformationContainer>
                  <SidePanelContainer>
                    <OrderTest patientId={patientId} />
                    <ActivateKit patientId={patientId} />
                  </SidePanelContainer>
                </div>
                <div>
                  <PatientTestTable
                    testHistory={patient.testHistory}
                    getResultReport={getResultReport}
                    loading={getReportLoading}
                    error={getReportError}
                  />
                </div>
              </PageContainer>
            </LoadingErrorWrapper>
          </>
        )}
      />
    </div>
  );
};

export default PatientProfilePage;
