import { ApolloError } from '@apollo/client';
import React from 'react';
import SyncLoader from 'react-spinners/SyncLoader';

import LoaderContainer from './styles';

const LoadingErrorWrapper = ({
  loading,
  error,
  children,
  styles,
}: {
  loading?: boolean;
  error?: ApolloError | Error;
  children: React.ReactNode;
  styles?: { width?: string; height?: string };
}) => {
  return (
    <React.Fragment>
      {error ? (
        <div>{error.message}</div>
      ) : loading ? (
        <LoaderContainer data-testid="loading-spinner" {...styles}>
          <SyncLoader color={'#448fffb8'} size={35} />
        </LoaderContainer>
      ) : (
        children
      )}
    </React.Fragment>
  );
};

export default LoadingErrorWrapper;
