import { useHistory } from 'react-router-dom';

import searchIcon from 'assets/images/large-search-icon.png';
import { Patient, SearchPatientRow } from 'types';
import ResultRow from './components/ResultRow';

import ResultsContainer, {
  ResultHeaderText,
  SearchCopy,
  SearchHeader,
  ResultsHeader,
  HeaderColumn,
  NoResultsHeader,
  NoResultsSubheader,
  ResultBanner,
  ResultRowContainer,
} from './styles';
import { formatPatient } from './utils';

const SearchResults = ({
  searchResults,
  searchType,
  searchTerm,
}: {
  searchResults: Patient[] | null;
  searchType: string;
  searchTerm: string;
}) => {
  const history = useHistory();

  const onViewProfile = (patient: SearchPatientRow) =>
    history.push(`/profile/${patient.id}`);

  const renderResults = () =>
    searchResults?.map((result) => (
      <ResultRow
        key={result.id}
        patient={formatPatient(result)}
        onViewProfile={onViewProfile}
        searchType={searchType}
        searchTerm={searchTerm}
      />
    ));

  const renderResultsHeader = () => {
    return (
      <ResultsHeader>
        <HeaderColumn>Patient Name</HeaderColumn>
        <HeaderColumn>Email</HeaderColumn>
        <HeaderColumn>Test Type</HeaderColumn>
        <HeaderColumn>Kit ID</HeaderColumn>
        <HeaderColumn>Kit Status</HeaderColumn>
        <HeaderColumn>Patient Record</HeaderColumn>
      </ResultsHeader>
    );
  };

  if (!searchResults) {
    return (
      <ResultsContainer>
        <>
          <img src={searchIcon} />
          <SearchHeader>Begin your search above</SearchHeader>
          <SearchCopy>
            You can search for patient data by entering their kit ID, <br />{' '}
            first name, last name, email, or tracking code.
          </SearchCopy>
        </>
      </ResultsContainer>
    );
  }

  return (
    <>
      {searchResults.length > 20 ? (
        <ResultBanner>
          There are {searchResults.length} returned results. Please deselect as
          many categories as possible to narrow your search
        </ResultBanner>
      ) : null}
      <ResultHeaderText>
        {searchResults?.length
          ? `${searchResults.length} patients found`
          : 'Results'}
      </ResultHeaderText>
      <ResultsContainer>
        <ResultRowContainer>
          {searchResults?.length ? (
            <>
              {renderResultsHeader()}
              {renderResults()}
            </>
          ) : (
            <>
              <img src={searchIcon} />
              <NoResultsHeader>No results found</NoResultsHeader>
              <NoResultsSubheader>
                Please expand your search and try again.
              </NoResultsSubheader>
            </>
          )}
        </ResultRowContainer>
      </ResultsContainer>
    </>
  );
};

export default SearchResults;
