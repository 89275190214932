export const options = [
  {
    value: 'Shipped to Patient',
    label: 'Shipped to Patient',
  },
  {
    value: 'Delivered to Patient',
    label: 'Delivered to Patient',
  },
  {
    value: 'Shipped to Lab',
    label: 'Shipped to Lab',
  },
  {
    value: 'Delivered to Lab',
    label: 'Delivered to Lab',
  },
  {
    value: 'Confirmed Recipt to Lab',
    label: 'Confirmed Recipt to Lab',
  },
  {
    value: 'Partially Resulted',
    label: 'Partially Resulted',
  },
  {
    value: 'Fully Resulted',
    label: 'Fully Resulted',
  },
  {
    value: 'Rejected',
    label: 'Rejected',
  },
];
