import { useEffect, useState } from 'react';
import { SearchPatientRow, TestHistory } from 'types';
import ResultRowContainer, {
  RowColumn,
  TimeStamp,
  ViewProfileButton,
} from './styles';

const ResultRow = ({
  patient,
  onViewProfile,
  searchType,
  searchTerm,
}: {
  patient: SearchPatientRow;
  // eslint-disable-next-line no-unused-vars
  onViewProfile: (patient: SearchPatientRow) => void;
  searchType: string;
  searchTerm: string;
}) => {
  const onClick = () => onViewProfile(patient);
  const [multiSearch, setMultiSearch] = useState(false);

  const formatDate = (dateString: string | undefined) => {
    const testDate = dateString?.match(/\d{4}(-\d{2}){2}/);
    const formattedDate = testDate ? testDate[0].replace(/-/g, '/') : null;
    return formattedDate;
  };

  const findTest = (searchTerm: string) => {
    if (patient.testHistory) {
      const foundTest = patient.testHistory.find(
        (test) =>
          test.kitId === searchTerm ||
          test.trackingLab?.trackingNumber === searchTerm,
      );
      return foundTest;
    }
    return null;
  };
  useEffect(() => {
    setMultiSearch(
      searchType === 'name' ||
        searchType === 'family' ||
        searchType === 'telecom',
    );
  }, [searchType]);
  return (
    <>
      {patient.testHistory?.length && multiSearch ? (
        patient.testHistory.map((test, i) => (
          <ResultRowItem
            key={i}
            patient={patient}
            test={test}
            onClick={onClick}
            date={formatDate(test.kitStatus?.[0].timestamp)}
          />
        ))
      ) : searchType === 'kitId' || searchType === 'trackingNumber' ? (
        <ResultRowItem
          patient={patient}
          test={findTest(searchTerm)}
          onClick={onClick}
          date={formatDate(findTest(searchTerm)?.kitStatus?.[0].timestamp)}
        />
      ) : (
        <ResultRowItem
          patient={patient}
          test={patient.testHistory?.[0]}
          onClick={onClick}
        />
      )}
    </>
  );
};

const ResultRowItem = ({
  patient,
  test,
  onClick,
  date,
}: {
  patient: SearchPatientRow;
  test?: TestHistory | null;
  onClick: () => void;
  date?: string | null;
}) => (
  <ResultRowContainer>
    <RowColumn>{patient.name}</RowColumn>
    <RowColumn>{patient.email}</RowColumn>
    <RowColumn>{test?.testType}</RowColumn>
    <RowColumn>{test?.kitId}</RowColumn>
    <RowColumn>
      {test?.kitStatus?.[0].status}
      {date ? <TimeStamp>Last updated on {date}</TimeStamp> : null}
    </RowColumn>
    <RowColumn>
      <ViewProfileButton onClick={onClick}>
        View Patient Profile
      </ViewProfileButton>
    </RowColumn>
  </ResultRowContainer>
);

export default ResultRow;
