import { FieldError } from 'react-hook-form';
import styled from 'styled-components';

type ButtonProps = {
  errors: {
    searchTerm?: FieldError;
    searchType?: {
      value?: FieldError;
      label?: FieldError;
    };
  };
};

export const SearchBarContainer = styled.div`
  display: flex;
  margin: 26px 0 22px 24px;
  flex-direction: column;
`;

export const SearchButton = styled.button<ButtonProps>`
  background: black;
  color: white;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  height: 42px;
  width: 133px;
  border: none;
  margin-left: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const SearchHelperText = styled.h5`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
  margin-bottom: 0;
`;
