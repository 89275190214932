import { useState } from 'react';
import { KitStatus as kitStatus, TestHistory } from 'types';
import format from 'date-fns/format';
import { useParams } from 'react-router-dom';
import { Fab } from '@material-ui/core';

import TestRowContainer, {
  KitStatus,
  RowColumn,
  ViewRejectedReason,
  ViewResultsButton,
  ViewQuestionIcon,
  CustomFab,
} from './styles';

const reportStatuses = ['Fully Resulted By Lab', 'Partially Resulted By Lab'];

const TestRow = ({
  test: {
    activationDate,
    kitId,
    testType,
    kitStatus,
    trackingLab,
    trackingPatient,
  },
  getResultReport,
}: {
  test: TestHistory;
  // eslint-disable-next-line no-unused-vars
  getResultReport: (kitId: string, patientId: string) => void;
}) => {
  const NO_ACTIVATION_DATE_LABEL = 'Not activated yet';
  const NO_STATUS_TIMESTAMP_LABEL = 'Unknown date';
  const [isExpanded, setIsExpanded] = useState(false);

  const { patientId } = useParams<{ patientId: string }>();

  const getTrackingUrl = () => {
    if (!trackingPatient) return;
    if (trackingPatient.carrier.toUpperCase() === 'UPS') {
      return `https://www.ups.com/track?loc=null&tracknum=${trackingPatient.trackingNumber}&requester=MB/trackdetails`;
    }
    if (trackingPatient.carrier.toUpperCase() === 'USPS') {
      return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingPatient.trackingNumber}`;
    }
    if (trackingPatient.carrier.toUpperCase() === 'FEDEX') {
      return `https://www.fedex.com/fedextrack/?tracknumbers=${trackingPatient.trackingNumber}&cntry_code=us`;
    }
  };

  const onGetReport = () => getResultReport(kitId, patientId);

  const getKitStatus = (status: string) =>
    status === 'Fully Resulted By Lab' ? 'Resulted' : status;

  const renderReasonIcon = (kitStatus: kitStatus) => {
    const rejectedReason = kitStatus?.rejectionReasons
      ? kitStatus?.rejectionReasons[kitStatus?.rejectionReasons.length - 1]
          .message
      : 'No reason';

    if (kitStatus?.status === 'Rejected By Lab') {
      return (
        <CustomFab>
          <Fab
            size="small"
            color="secondary"
            aria-label="add"
            style={{
              background: '#0149b0',
              transform: 'scale(0.7)',
              marginRight: '5px',
            }}
            data-testid="reason-icon"
          >
            <ViewQuestionIcon>?</ViewQuestionIcon>
          </Fab>
          <ViewRejectedReason data-testid="reason-message">
            {rejectedReason.toLocaleLowerCase()}
          </ViewRejectedReason>
        </CustomFab>
      );
    }

    return null;
  };

  const renderKitStatus = () => {
    if (kitStatus?.length === 1) {
      return (
        <KitStatus expanded={false} dropdown={false}>
          {getKitStatus(kitStatus[0].status)} -{' '}
          {kitStatus[0].timestamp
            ? format(new Date(kitStatus[0].timestamp), 'MMMM d, y')
            : NO_STATUS_TIMESTAMP_LABEL}
          {renderReasonIcon(kitStatus[0])}
          {reportStatuses.includes(kitStatus?.[0].status) ? (
            <>
              {' '}
              -{' '}
              <ViewResultsButton onClick={onGetReport}>
                View Results
              </ViewResultsButton>
            </>
          ) : null}
        </KitStatus>
      );
    }
    if (kitStatus && !isExpanded) {
      return (
        <KitStatus expanded={isExpanded}>
          {getKitStatus(kitStatus[0].status)} -{' '}
          {kitStatus[0].timestamp
            ? format(new Date(kitStatus[0].timestamp), 'MMMM d, y')
            : NO_STATUS_TIMESTAMP_LABEL}
          {renderReasonIcon(kitStatus[0])}
          {reportStatuses.includes(kitStatus?.[0].status) ? (
            <>
              {' '}
              -{' '}
              <ViewResultsButton onClick={onGetReport}>
                View Results
              </ViewResultsButton>
            </>
          ) : null}
        </KitStatus>
      );
    }
    return kitStatus?.map((status) => (
      <KitStatus expanded={isExpanded} key={status.timestamp}>
        {getKitStatus(status.status)} -{' '}
        {status.timestamp
          ? format(new Date(status.timestamp), 'MMMM d, y')
          : NO_STATUS_TIMESTAMP_LABEL}
        {renderReasonIcon(status)}
        {reportStatuses.includes(status.status) ? (
          <>
            {' '}
            -{' '}
            <ViewResultsButton onClick={onGetReport}>
              View Results
            </ViewResultsButton>
          </>
        ) : null}
      </KitStatus>
    ));
  };

  const toggleIsExpanded = () => setIsExpanded((prevState) => !prevState);

  return (
    <TestRowContainer>
      <RowColumn>{testType}</RowColumn>
      <RowColumn>{kitId}</RowColumn>
      <RowColumn>
        {activationDate
          ? format(new Date(activationDate), 'MMMM d, y')
          : NO_ACTIVATION_DATE_LABEL}
      </RowColumn>
      <RowColumn onClick={toggleIsExpanded}>{renderKitStatus()}</RowColumn>
      <RowColumn>
        <a href={getTrackingUrl()} target="#">
          {trackingPatient?.trackingNumber ?? ''}
        </a>
      </RowColumn>
      <RowColumn>
        <a
          href={`https://www.ups.com/track?loc=null&tracknum=${
            trackingLab?.trackingNumber ?? ''
          }&requester=MB/trackdetails`}
          target="#"
          style={{ wordBreak: 'break-word' }}
        >
          {trackingLab?.trackingNumber ?? ''}
        </a>
      </RowColumn>
    </TestRowContainer>
  );
};

export default TestRow;
