import { FieldError } from 'react-hook-form';
import styled from 'styled-components';
import error from 'assets/images/error.png';

interface InputParams {
  error?: FieldError;
}

export default styled.input<InputParams>`
  border: 1px solid #b9c8d3;
  border-color: ${({ error }) => (error ? '#DC412C' : '#b9c8d3')};
  border-radius: 4px;
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.64px;
  color: #000000;
  padding: 15px;
  margin-bottom: ${({ error }) => (error ? '10px' : '20px')};
`;

export const SearchInputError = styled.p`
  background: url(${error}) no-repeat;
  padding-left: 20px;
  color: #dc412c;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.415385px;
  margin-top: 0;
  margin-bottom: 24px;
`;
