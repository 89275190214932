import styled, { CSSObject } from 'styled-components';

export default styled.div`
  width: 270px;
  margin-left: 240px;
`;

export const dropdownStyles = {
  control: (provided: CSSObject) => ({
    ...provided,
    border: '1px solid #626262',
    height: '42px',
    borderRadius: '0px 4px 4px 0',
  }),
};
