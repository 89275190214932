import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import Input from 'components/Input';
import Button from 'components/Button';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper';

import checkmark from 'assets/images/checkmark.png';
import { Patient, PatientInput } from 'types';

import { CREATE_PATIENT } from './queries';
import {
  createPatientBody,
  defaultValues,
  formatPatient,
  statesArray,
} from './utils';
import {
  FormLabel,
  FormContainer,
  LabelContainer,
  FormSection,
  FormRow,
  FormSubheader,
  CreateSuccessContainer,
  CreateSuccessCopy,
} from './styles';

const PatientForm = ({
  onCreateSuccess,
  patient,
}: {
  onCreateSuccess?: () => void;
  patient?: Patient | null;
}) => {
  const history = useHistory();
  const [patientId, setPatientId] = useState('');
  const [createSuccess, setCreateSuccess] = useState<null | boolean>(null);
  const { handleSubmit, control, reset, setValue } = useForm<PatientInput>({
    mode: 'onBlur',
    defaultValues,
  });

  useEffect(() => {
    if (patient?.name) {
      const formattedPatient = formatPatient(patient);
      (
        Object.keys(formattedPatient) as Array<keyof typeof formattedPatient>
      ).forEach((key) => setValue(key, formattedPatient[key]));
    }
  }, [patient, setValue]);

  const [createPatient, { loading, error }] = useMutation(CREATE_PATIENT, {
    onError: (err) => console.error(err),
    onCompleted: ({ createPatient }) => {
      setPatientId(createPatient.id);
      setCreateSuccess(true);
      if (onCreateSuccess) {
        onCreateSuccess();
      }
    },
  });

  const onSubmit: SubmitHandler<PatientInput> = (data) =>
    createPatient({
      variables: {
        patientInput: createPatientBody(data),
      },
    });

  const onCreatePatient = handleSubmit((data) => onSubmit(data));
  const onViewProfile = () => history.push(`/profile/${patientId}`);
  const onResetPage = () => {
    setCreateSuccess(null);
    setPatientId('');
    reset(defaultValues);
  };

  if (createSuccess) {
    return (
      <CreateSuccessContainer>
        <img src={checkmark} height="45px" width="45px" />
        <CreateSuccessCopy>Patient was successfully created</CreateSuccessCopy>
        <Button
          onClick={onViewProfile}
          styles={{
            padding: '10px 46px',
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          View Patient Profile
        </Button>
        <Button
          secondary
          onClick={onResetPage}
          styles={{
            padding: '10px 46px',
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          Create New Patient
        </Button>
      </CreateSuccessContainer>
    );
  }

  return (
    <LoadingErrorWrapper
      loading={loading}
      error={error}
      styles={{ height: '100vh' }}
    >
      <LabelContainer>
        <FormLabel>PATIENT INFORMATION</FormLabel>
      </LabelContainer>
      <FormContainer onSubmit={onCreatePatient} id="patientForm">
        <FormSection>
          <FormRow>
            <Input
              label="First Name*"
              labelFor="firstName"
              type="text"
              id="lastName"
              control={control}
              disabled={!!patient}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
            />
            <Input
              label="Last Name*"
              labelFor="lastName"
              type="text"
              id="lastName"
              control={control}
              disabled={!!patient}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
            />
            <Input
              label="Email*"
              labelFor="email"
              type="text"
              id="email"
              control={control}
              disabled={!!patient}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              label="Phone Number*"
              labelFor="phone"
              type="text"
              id="phone"
              control={control}
              disabled={!!patient}
              phoneNumber
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
                pattern: {
                  value: /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                  message: 'Invalid phone number',
                },
              }}
            />
            <Input
              label="Sex Assigned at Birth*"
              labelFor="gender"
              id="gender"
              control={control}
              disabled={!!patient}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
              selectOptions={[
                { label: 'Female', value: 'female' },
                { label: 'Male', value: 'male' },
                { label: 'Other', value: 'other' },
              ]}
            />
            <Input
              label="Date of Birth (MM/DD/YYYY)*"
              labelFor="dob"
              id="dob"
              type="date"
              control={control}
              disabled={!!patient}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
            />
          </FormRow>
        </FormSection>
        <FormSection>
          <FormSubheader>Address</FormSubheader>
          <FormRow>
            <Input
              label="Street Address*"
              labelFor="addressLine1"
              type="text"
              id="addressLine1"
              control={control}
              disabled={!!patient}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
            />
            <Input
              label="Apt Or Suite Number"
              labelFor="addressLine2"
              type="text"
              id="addressLine2"
              control={control}
              disabled={!!patient}
            />
            <Input
              label="City*"
              labelFor="city"
              type="text"
              id="city"
              control={control}
              disabled={!!patient}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
            />
            <Input
              label="State*"
              labelFor="state"
              type="text"
              id="state"
              control={control}
              disabled={!!patient}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
              selectOptions={statesArray}
            />
          </FormRow>
          <FormRow>
            <Input
              label="Zip Code*"
              labelFor="zip"
              type="text"
              id="zip"
              control={control}
              disabled={!!patient}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
            />
          </FormRow>
        </FormSection>
        <FormSection>
          <FormSubheader>
            Emergency Contact Information (Optional)
          </FormSubheader>
          <FormRow>
            <Input
              label="Name"
              labelFor="emergencyContactName"
              type="text"
              id="emergencyContactName"
              control={control}
              disabled={!!patient}
            />
            <Input
              label="Phone Number"
              labelFor="emergencyContactNumber"
              type="text"
              id="emergencyContactNumber"
              control={control}
              disabled={!!patient}
              phoneNumber
              rules={{
                pattern: {
                  value: /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                  message: 'Invalid phone number',
                },
              }}
            />
            <Input
              label="Relationship"
              labelFor="emergencyContactRelationship"
              id="emergencyContactRelationship"
              control={control}
              disabled={!!patient}
              selectOptions={[
                { label: 'Parent', value: 'parent' },
                { label: 'Child', value: 'child' },
                { label: 'Sibling', value: 'sibling' },
                { label: 'Grandparent', value: 'grandparent' },
                { label: 'Spouse', value: 'spouse' },
                { label: 'Partner', value: 'partner' },
                { label: 'Other', value: 'other' },
              ]}
            />
          </FormRow>
          {!patient && (
            <div style={{ width: 275, marginLeft: 15, marginTop: 30 }}>
              <Button
                onClick={onCreatePatient}
                dataTestId="create-patient-button"
              >
                Create Patient
              </Button>
            </div>
          )}
        </FormSection>
      </FormContainer>
    </LoadingErrorWrapper>
  );
};

export default PatientForm;
